import React from 'react'
import '../css/resultsTablesEtc.css'
import InnerHTML from 'dangerously-set-html-content'


export default function IssoComments() {

  const html = `
  <script data-isso="comments" src="js/embed.min.js"></script>
        `

  return (
    <>
      
      <InnerHTML html={html} />
      <section id="isso-thread"></section>
    </>
      
  )
}

